


















import ShopAPI from "../../common/api/api_shop";
import Vue from "vue";
import _ from "lodash";
import store from "../../store/shop";
import notificationMixin from "../../mixin/notification";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component("CategoryGroup", {
  components: {
    SHOrderCardSkeleton: () =>
      import("../../components/components/SHOrderCardSkeleton.vue"),
    QLink: () => import("../../components/elements/QLink.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mounted() {
    api.set_token(store.state.token);
  },
  data() {
    return {
      category_group_id: this.$route.params.category_group_id,
      isLoading: false,
      categories: [],
      category_group_temps: [],
      category_groups: [],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.category_group_id = to.params.category_group_id;
    next();
  },
  watch: {
    menuChange(newVal, oldVal) {
      if (newVal != "") {
        this.fetchCategories();
      }
    },
  },
  computed: {
    categoryGroups() {
      let result = [];

      _.forEach(store.state.categoryGroups, (category_group) => {
        if (!this.hasValidCategory(category_group)) {
          return;
        }

        if (
          (category_group.id == this.category_group_id &&
            category_group.category_id) ||
          category_group.parent_id == this.category_group_id
        ) {
          category_group.type = 1;

          if (category_group.category_id) {
            let category = this.getCategory(category_group.category_id);
            category_group.name = category.name;
            category_group.type = category.type;
          }

          category_group.link = `/${this.prefix}/${this.shoppix}/soldout/categories/${category_group.category_id}`;

          if (this.hasChild(category_group.id)) {
            category_group.link = `/${this.prefix}/${this.shoppix}/soldout/category_group/${category_group.id}`;
          }

          result.push(category_group);
        }
      });

      result = _.orderBy(result, ["rank"], ["asc"]);

      return result;
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    menuChange() {
      return store.state.pageChange.menu;
    },
  },

  mixins: [notificationMixin],
  methods: {
    fetchCategories() {
      let message = "";
      api
        .soldout_index()
        .then((response) => {
          this.categories = response.data.data.categories;
          this.category_group_temps = response.data.data.category_groups;
          this.category_groups = [];
          _.forEach(this.category_group_temps, (category_group) => {
            if (!this.hasValidCategory(category_group)) {
              return;
            }

            if (!category_group.parent_id) {
              category_group.type = 1;

              if (category_group.category_id) {
                let category = this.getCategory(category_group.category_id);
                category_group.name = category.name;
                category_group.type = category.type;
              }

              category_group.link = `/${this.prefix}/${this.shoppix}/soldout/categories/${category_group.category_id}`;

              if (this.hasChild(category_group.id)) {
                category_group.link = `/${this.prefix}/${this.shoppix}/soldout/category_group/${category_group.id}`;
              }

              this.category_groups.push(category_group);
            }
          });

          this.category_groups = _.orderBy(
            this.category_groups,
            ["rank"],
            ["asc"]
          );
          // set new menu
          this.setCategoryGroups();
          this.setCategories();
        });
    },
    getCategory(id) {
      return _.find(store.state.categories, (category) => category.id === id);
    },
    hasChild(id) {
      let result = false;

      let child = _.find(
        store.state.categoryGroups,
        (category_group) => category_group.parent_id === id
      );

      return !!child;
    },
    hasValidCategory(category_group) {
      let result = false;

      if (category_group.category_id) {
        let category = this.getCategory(category_group.category_id);

        if (category) {
          result = true;
        }
      }

      let childs = _.filter(
        store.state.categoryGroups,
        (categoryGroup) => categoryGroup.parent_id === category_group.id
      );

      _.forEach(childs, (child) => {
        result = result ? result : this.hasValidCategory(child);
      });

      return result;
    },
    toCatStr(catId: number) {
      switch (catId) {
        case 1:
          return "";
        case 2:
          return "takeout";
        case 3:
          return "catering";
      }

      return "";
    },
    setCategories() {
      if (!this.categories) {
        return;
      }

      store.commit("setCategories", this.categories);
    },
    setCategoryGroups() {
      if (!this.category_group_temps) {
        return;
      }

      store.commit("setCategoryGroups", this.category_group_temps);
    },
  },
});
